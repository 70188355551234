<template>
  <section class="connections">
    <div 
      class="connection" 
      v-for="plat in user.platforms" 
      :key="plat.provider"
      :style="{ 'background-color' : platData(plat.provider).color }"
    >
      <i :class="platData(plat.provider).icon"></i>
      <span> {{ plat.name }} </span>
      <button 
        :disabled="onlyPlatAndNoPassword" 
        :title="onlyPlatAndNoPassword ? 'Unable to remove your only way to login!' : ''"
        @click="removeConnection(plat.provider)"
      >Remove</button>
    </div>
    <div class="no-more" v-if="user.platforms.length === 0">
      <span>You have no active connections</span>
    </div>
    <div class="hr">
      <hr />
      <span>Add a new connection</span>
      <hr />
    </div>
    <div class="connection add" v-for="plat in noPlats" :key="plat.name" :style="{ 'border-color' : plat.color }">
      <i :class="plat.icon"></i>
      <span> {{ plat.title }} </span>
      <button v-if="!plat.comingSoon" :style="{ 'background-color' : plat.color }" @click="addConnection(plat.name)">Connect</button>
      <button v-else :style="{ 'background-color' : plat.color }">Coming Soon</button>
    </div>
    <div class="no-more" v-if="noPlats.length === 0">
      <span>No more connections to add</span>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    plats() {
      return this.$store.state.user.platforms.map((p) => p.provider);
    },
    noPlats() {
      return this.$platforms.filter(
        (plat) => !this.plats.includes(plat.name)
      );
    },
    onlyPlatAndNoPassword() {
      return !this.$store.state.user.hasPassword && this.$store.state.user.platforms.length === 1;
    }
  },
  methods: {
    platData(platform) {
      return this.$platforms.find((plat) => plat.name === platform);
    },
    removeConnection(platform) {
      if (this.onlyPlatAndNoPassword) return;
      axios.delete(this.$api(`/user/platforms/${platform}`), {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`,
        }
      })
        .then(() => {
          this.$store.dispatch('updateUser');
          this.$notify({
            type: 'success',
            text: `Successfully removed ${platform} connection`
          });
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    addConnection(plat) {
      //this.$store.commit("setRedirect", `/settings/connections`);
      window.location.href = this.$api(`/user/${plat}`);
    }
  },
};
</script>

<style>
</style>